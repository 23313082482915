import { createAsync, query } from "@solidjs/router";
import { For } from "solid-js";
import { gql } from "~/__gql-generated__";

import rootStyles from "~/components/Root.module.scss";
import SmartA from "~/components/SmartA";
import styles from "~/components/TopTags.module.scss";
import { client } from "~/utils/graphql";

const TOP_TAGS = gql(`
  query TopTags {
    tags(options: { limit: 20, sort: [{ usageCount: DESC }] }) {
      title
      slug
      usageCount
    }
  }
`);

const getTags = query(async () => {
  "use server";

  const result = await client.query({ query: TOP_TAGS });

  return result.data.tags;
}, "tags");

export default function TopTags() {
  const tags = createAsync(() => getTags());

  return (
    <section class={rootStyles["styled-links"]}>
      <ul classList={{ [styles["tag-list"]]: true }}>
        <For each={tags()}>
          {(tag) => (
            <li>
              <SmartA href={`/tag/${tag.slug}`}>
                <span class={styles.hashtag}>#</span>
                {tag.title}
              </SmartA>
            </li>
          )}
        </For>
        <li>
          <SmartA href="/artigos">Todos os Artigos</SmartA>
        </li>
      </ul>
    </section>
  );
}
