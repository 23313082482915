import Tooltip from "@corvu/tooltip";

import { createSignal } from "solid-js";

import rootStyles from "~/components/Root.module.scss";
import styles from "~/components/player-home/SongHistoryButton.module.scss";

interface SongHistoryButtonProps {
  readonly alternate?: boolean;
  readonly onClick: () => void;
}

export default function SongHistoryButton(props: SongHistoryButtonProps) {
  const [pressed, setPressed] = createSignal(false);

  return (
    <Tooltip
      placement="top"
      strategy="fixed"
      openDelay={200}
      hoverableContent={false}
      floatingOptions={{
        offset: 10,
        flip: true,
        shift: true,
      }}
    >
      <Tooltip.Trigger
        type="button"
        classList={{
          [styles.button]: true,
          [styles.alternate]: props.alternate,
        }}
        aria-pressed={pressed()}
        onClick={() => {
          props.onClick();
          setPressed((old) => !old);
        }}
      >
        <IconTablerHistory />
        <span class={rootStyles["sr-only"]}>Músicas Recentes</span>
      </Tooltip.Trigger>
      <Tooltip.Portal>
        <Tooltip.Content>
          Músicas Recentes
          <Tooltip.Arrow size={10} />
        </Tooltip.Content>
      </Tooltip.Portal>
    </Tooltip>
  );
}
