import { createAsync, query } from "@solidjs/router";

import { Show, Suspense, createSignal } from "solid-js";

import styles from "~/components/LatestVideo.module.scss";
import rootStyles from "~/components/Root.module.scss";
import SmartA from "~/components/SmartA";
import { parseXml } from "~/utils/parseXml";

const getLatestVideo = query(async () => {
  "use server";

  const response = await fetch(
    `https://youtube.com/feeds/videos.xml?channel_id=${import.meta.env.VITE_YOUTUBE_CHANNEL_ID}`,
  );
  const data = parseXml(await response.text());

  const id = data.feed.entry[0]["yt:videoId"] as string;
  const authorUrl = data.feed.author.uri as string;
  const published = new Date(data.feed.entry[0].published).toLocaleString(
    import.meta.env.VITE_LOCALE,
    {
      dateStyle: "short",
      timeZone: import.meta.env.VITE_TZ,
    },
  );
  const title = data.feed.entry[0]["media:group"]["media:title"] as string;
  const thumbnail = data.feed.entry[0]["media:group"]["media:thumbnail"][
    "@_url"
  ] as string;

  return { id, authorUrl, published, title, thumbnail };
}, "latestVideo");

export default function LatestVideo() {
  const data = createAsync(() => getLatestVideo());
  const [switched, setSwitched] = createSignal(false);

  return (
    <Suspense>
      <section
        classList={{ [styles.wrapper]: true, [rootStyles.section]: true }}
      >
        <h2>
          Último Vídeo do Canal
          <span>
            <IconTablerDeviceTvOld />
          </span>
        </h2>
        <Show
          when={switched()}
          fallback={
            <a
              class={styles.inner}
              href={`https://www.youtube.com/watch?v=${data()?.id ?? ""}`}
              onClick={(event) => {
                event.preventDefault();
                setSwitched(true);
              }}
            >
              <img loading="lazy" src={data()?.thumbnail} alt={data()?.title} />
            </a>
          }
        >
          <div class={styles.inner}>
            <iframe
              width="560"
              height="315"
              loading="lazy"
              src={`https://www.youtube-nocookie.com/embed/${data()?.id ?? ""}?autoplay=1`}
              title={data()?.title}
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
              referrerPolicy="strict-origin-when-cross-origin"
              allowfullscreen
            />
          </div>
        </Show>
        <p>
          <span class={styles.meta}>Postado a {data()?.published ?? ""}</span>
          <SmartA href={data()?.authorUrl ?? ""}>Ver Mais</SmartA>
        </p>
      </section>
    </Suspense>
  );
}
