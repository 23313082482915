import { clientOnly } from "@solidjs/start";
import { Show, createSignal } from "solid-js";

import { usePlayer } from "~/components/PlayerContext";
import rootStyles from "~/components/Root.module.scss";
import SkeletonPlaceholder from "~/components/SkeletonPlaceholder";
import CastButton from "~/components/player-home/CastButton";
import Progress from "~/components/player-home/Progress";
import SongHistoryButton from "~/components/player-home/SongHistoryButton";
import SongLyricsButton from "~/components/player-home/SongLyricsButton";
import SongRequestButton from "~/components/player-home/SongRequestButton";
import SourceSelect from "~/components/player-home/SourceSelect";
import styles from "~/components/player-home/index.module.scss";

const Toolbar = clientOnly(() => import("~/components/player-home/Toolbar"));
const Effect = clientOnly(() => import("~/components/player-home/Effect"));
const RadioShowCover = clientOnly(
  () => import("~/components/player-home/RadioShowCover"),
);
const RadioShowMetadata = clientOnly(
  () => import("~/components/player-home/RadioShowMetadata"),
);
const SongHistory = clientOnly(
  () => import("~/components/player-home/SongHistory"),
);

export default function PlayerHome() {
  const player = usePlayer();
  const [showSongHistory, setShowSongHistory] = createSignal(false);
  const isLoaded = () => Boolean(player.metadata);

  return (
    <section
      id="player-home"
      classList={{ [styles.wrapper]: true, [styles.skeleton]: !isLoaded() }}
    >
      <h2 class={rootStyles["sr-only"]}>No Ar</h2>
      <Show when={isLoaded()} fallback={<Skeleton />}>
        <div class={styles.inner}>
          <RadioShowMetadata />
          <SongHistory visible={showSongHistory()} />
        </div>
        <RadioShowCover />
        <div class={styles.bottom}>
          <Effect />
          <SongHistoryButton
            alternate
            onClick={() => {
              setShowSongHistory((old) => !old);
            }}
          />
          <Toolbar />
          <div class={styles.progress}>
            <Progress alternate />
          </div>
          <div class={styles.buttons}>
            <CastButton alternate />
            <SourceSelect alternate />
            <SongLyricsButton alternate />
            <SongHistoryButton
              onClick={() => {
                setShowSongHistory((old) => !old);
              }}
            />
            <SongRequestButton alternate />
          </div>
        </div>
      </Show>
    </section>
  );
}

function Skeleton() {
  return (
    <>
      <div class={styles.inner} aria-hidden="true">
        <div class={styles.title}>
          <SkeletonPlaceholder class={styles.avatar} />
          <div class={styles.text}>
            <h3>
              <SkeletonPlaceholder inline>
                Playlist Non-Stop
              </SkeletonPlaceholder>
            </h3>
            <p>
              <SkeletonPlaceholder inline>
                Playlist · Variado
              </SkeletonPlaceholder>
            </p>
          </div>
        </div>
        <p>
          <SkeletonPlaceholder inline class={styles["image-alternate"]} />
          <SkeletonPlaceholder inline />
          <SkeletonPlaceholder inline />
          <SkeletonPlaceholder inline />
          <SkeletonPlaceholder inline />
          <SkeletonPlaceholder inline />
        </p>
      </div>
      <SkeletonPlaceholder class={styles.image} />
      <SkeletonPlaceholder class={styles.bottom} />
    </>
  );
}
